<template>
  <div class="home-content">
    <popup-expired-registration v-if="status === 'expired'"/>
    <popup-done-registration v-else-if="status === 'done'"/>
    <popup-failed-registration v-else-if="status === 'failed'"/>
    <popup-error v-else/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useGlobalStore} from '@/stores/global';

import PopupError from '@/components/popup/PopupError.vue';
import PopupDoneRegistration from '@/components/popup/PopupDoneRegistration.vue';
import PopupFailedRegistration from '@/components/popup/PopupFailedRegistration.vue';
import PopupExpiredRegistration from '@/components/popup/PopupExpiredRegistration.vue';
import {useSupplierStore} from '@/stores/supplier';

export default defineComponent({
  components: {
    PopupError,
    PopupDoneRegistration,
    PopupFailedRegistration,
    PopupExpiredRegistration
  },
  setup() {
    const globalStore = useGlobalStore();
    const supplierStore = useSupplierStore();
    return {
      supplierStore,
      globalStore
    };
  },
  data() {
    return {
      status: ''
    };
  },
  async created() {
    // expired  /  failed   / done
    this.status = this.$route.query.result as string;
  }

});
</script>

<style lang="less">

</style>
