import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import App from './App.vue';
// @ts-ignore
import VueGtag from 'vue-gtag';

import MainPage from '@/views/MainPage.vue';
import SteamPage from '@/views/SteamPage.vue';
import SupplierAuthPage from '@/views/SupplierPages/SupplierAuthPage.vue';
import SupplierPersonalPage from '@/views/SupplierPages/SupplierRequestsPage.vue';
import SupplierFagPage from '@/views/SupplierPages/SupplierFagPage.vue';
import SupplierSupportPage from '@/views/SupplierPages/SupplierSupportPage.vue';
import SupplierBalancePage from '@/views/SupplierPages/SupplierBalancePage.vue';
import SupplierRequestPay from '@/views/SupplierPages/SupplierRequestPay.vue';
import SupplierVerificationPage from '@/views/SupplierPages/SupplierVerificationPage.vue';
import SupplierCartAdd from '@/views/SupplierPages/SupplierCartAdd.vue';
import TopUpStatusPage from '@/views/TopUpStatusPage.vue';
import RestorePasswordPage from '@/views/RestorePasswordPage.vue';
import RegistrationStatusPage from '@/views/RegistrationStatusPage.vue';
import { version } from '@/version';
import { useSupplierStore } from '@/stores/supplier';
import { useGlobalStore } from '@/stores/global';

const routes = [
  { path: '/', component: MainPage, meta: { template: 'TemplateMain' } },
  { path: '/steam', component: SteamPage, meta: { template: 'TemplateSteam' } },
  { path: '/topup/status/:id', component: TopUpStatusPage, meta: { template: 'TemplateSteam' } },
  { path: '/supplier/auth', component: SupplierAuthPage, meta: { template: 'TemplateMain' } },
  { path: '/supplier/auth/recover/:id', component: RestorePasswordPage, meta: { template: 'TemplateSupplier' } },
  { path: '/supplier/auth/registration', component: RegistrationStatusPage, meta: { template: 'TemplateSupplier' } },
  { path: '/supplier/profile/verification', component: SupplierVerificationPage, meta: { template: 'TemplateSupplier' } },
  { path: '/supplier', component: SupplierPersonalPage, meta: { template: 'TemplateSupplier', requiresAuth: true } },
  { path: '/supplier/balance', component: SupplierBalancePage, meta: { template: 'TemplateSupplier', requiresAuth: true } },
  { path: '/supplier/faq', component: SupplierFagPage, meta: { template: 'TemplateSupplier', requiresAuth: true } },
  { path: '/supplier/support', component: SupplierSupportPage, meta: { template: 'TemplateSupplier', requiresAuth: true } },
  { path: '/supplier/request/payfinish', component: SupplierRequestPay, meta: { template: 'TemplateEmpty' } },
  { path: '/supplier/cart/addfinish', component: SupplierCartAdd, meta: { template: 'TemplateEmpty' } }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const globalStore = useGlobalStore();
    const method: string = to.hash
      .replace(/#|\\|.| /, '')
      .replace(/(^.|_.)/g, a => {
        return a.length === 1 ? a : a[ 1 ].toUpperCase();
      });

    globalStore.setShowPopup(method);

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      };
    }
  }
});

router.beforeEach((to, from, next) => {
  const supplierStore = useSupplierStore();
  const routeNext = () => {
    const { isAuth } = supplierStore;
    if (to.meta.requiresAuth && !isAuth) {
      next('/supplier/auth/#form_login');
    } else if (to.fullPath === '/supplier/auth/#form_login' && isAuth) {
      next('/supplier');
    } else {
      next();
    }
  };

  const localAuthToken = localStorage.getItem('authToken');
  const { profileVerification, authToken } = supplierStore;

  if (!authToken && localAuthToken) { // пересохраняем токен при обновлении
    supplierStore.setAuthToken(localAuthToken);
    profileVerification().then(routeNext);
  } else {
    routeNext();
  }
});

export function logout() {
  router.push('/supplier/auth/#form_login');
  router.go(0);
}

console.log(`Version: ${version}`);

createApp(App)
  .use(ElementPlus)
  .use(createPinia())
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-X4W5CLQH90' }
  })
  .mount('#app');
