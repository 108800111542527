<template>
  <component :is="currentTemplate">
    <router-view/>
  </component>
  <kz-bullshit-popup
    :ref="'kzbullshit'"
    :element-id="'kzbullshit'"
    :debug="false"
    :position="'bottom-left'"
    :type="'floating'"
    :disable-decline="true"
    :transition-name="'slideFromBottom'"
    :show-postpone-button="false"
  >
    <template #message>
      <div
        class="popup"
      >
        <div class="popup-content">
          <h3>Уважаемые клиенты!</h3>
          <p>
            Просим вас помнить, что ваша личная информация, включая данные для входа в личный кабинет и платежные реквизиты,
            являются конфиденциальными и предназначены исключительно для вашего использования. Мы настоятельно рекомендуем не передавать свои данные третьим лицам.
            <br>Будьте внимательны: передача персональных данных третьим лицам может привести к угрозе безопасности вашего аккаунта, а также финансовым потерям.
            <br><br>Если вы заметили подозрительные действия или получаете сомнительные сообщения, немедленно свяжитесь с нашей службой поддержки.
            <br>С уважением,<br>ТОО "Topupme"
          </p>
        </div>
      </div>
    </template>
    <template #acceptContent>
      Я все понял!
    </template>
  </kz-bullshit-popup>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import TemplateSteam from '@/views/template/TemplateSteam.vue';
import TemplateSupplier from '@/views/template/TemplateSupplier.vue';
import TemplateMain from '@/views/template/TemplateMain.vue';
import TemplateEmpty from '@/views/template/TemplateEmpty.vue';
import CookieAcceptDecline from '@/views/KzBullshitPopup.vue';
import KzBullshitPopup from '@/views/KzBullshitPopup.vue';

export default defineComponent({
  components: {
    KzBullshitPopup,
    KzBullshitAcceptDecline: CookieAcceptDecline,
    TemplateSteam,
    TemplateSupplier,
    TemplateMain,
    TemplateEmpty
  },
  computed: {
    currentTemplate() {
      return this.$route.meta.template;
    }
  }
});
</script>

<style lang="less">
@import "~@/styles/app.less";
@import "~@/styles/navigation.less";
@import "~@/styles/template.less";
@import "~@/styles/collapse.less";
</style>
