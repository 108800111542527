import {defineStore} from 'pinia';
import {
  getDataStartSession,
  getDataTopUpForId,
  getDataTopUpList,
  postDataSupportMessage,
  postDataTopUpCheck,
  postDataTopUpCreate
} from '@/api';
import {useGlobalStore} from '@/stores/global';
import {RequestSupportMessage} from '@/api/types';

type checkParam = {
  steamAccount: string,
  usdAmount: number
  email: string
};

export const useClientStore = defineStore({
  id: 'clientStore',
  state: (): any =>
    ({
      session: {
        sessionId: '', // session ID
        sessionExpiresAt: 0, // unixtimestamp когда сессия истекае
        usdAmountMin: 0, // Минимальная сумма к пополнению единоразово в USD
        usdAmountMax: 0, // Максимальная сумма к пополнению единоразово в USD
        usdTotalAMountDayMax: 0, // Максимальная сумма к пополнению в день на один аккаунт в USD
        timeToTopup: 0 // Максимальное время ожидания пополнения в минутах
      },
      checkData: {
        success: false,
        errorMessage: '',
        amount: 0,
        currency: '',
        steamAccount: '',
        usdAmount: 0,
        email: ''
      },
      currentTopUp: {
        topupId: '',
        status: null,
        amountUsd: 0,
        account: '',
        paymentUrl: '',
        errorDescription: '',
        creationDate: 0,
        processedDate: 0
      },
      listTopUpLast: []
    }),
  actions: {
    async getTopUpList() {
      try {
        const {list} = await getDataTopUpList();
        this.listTopUpLast = [
          ...this.listTopUpLast,
          ...list.map(item => {
            return {
              ...item,
              key: (Math.random() + 1).toString(36).substring(7)
            };
          })
        ];
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    },
    deleteFirstItemTopUpList() {
      this.listTopUpLast.shift();
    },
    clearCurrentTopUp() {
      this.currentTopUp = {
        topupId: '',
        status: null,
        amountUsd: 0,
        account: '',
        paymentUrl: '',
        errorDescription: '',
        creationDate: 0,
        processedDate: 0
      };
    },
    async getTopUpForId(id: string) {
      try {
        this.currentTopUp = await getDataTopUpForId(id);
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    },
    async topUpCreate() {
      try {
        this.currentTopUp = await postDataTopUpCreate({
          sessionId: this.session.sessionId
        });
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    },
    async getStartSession() {
      try {
        this.session = await getDataStartSession();
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    },
    clearTopUpCheck() {
      this.checkData = {
        success: false,
        errorMessage: '',
        amount: 0,
        currency: '',
        steamAccount: '',
        usdAmount: 0,
        email: ''
      };
    },
    setCheckDataUsdAmount(usdAmount: number) {
      this.checkData = {...this.checkData, usdAmount};
    },
    async topUpCheck({steamAccount, usdAmount, email}: checkParam) {
      try {
        const checkData = await postDataTopUpCheck({
          sessionId: this.session.sessionId,
          steamAccount,
          usdAmount,
          email
        });

        this.checkData = {...this.checkData, ...checkData, steamAccount, usdAmount, email};
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    },
    async supportMessage(param: RequestSupportMessage) {
      try {
        return await postDataSupportMessage(param);
      } catch (e) {
        const globalStore = useGlobalStore();
        globalStore.showErrorRequestPopup();
      }
    }
  }
});
