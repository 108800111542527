<template>
  <div
    class="f-a-g-wrap"
  >
    <div
      class="f-a-g-content"
    >
      <div class="f-a-g-title">
        FAQ
      </div>

      <el-collapse accordion>
        <el-collapse-item
          title="Как работает сервис?"
          name="1"
        >
          Наш сервис позволяет заказчикам из России оплачивать покупки в Steam с помощью исполнителей из Казахстана.
          Вы, как исполнитель, оплачиваете заявки заказчиков в тенге, а взамен получаете комиссию за каждую выполненную заявку.
        </el-collapse-item>
        <el-collapse-item
          title="Как исполнитель зарабатывает на сервисе?"
          name="2"
        >
          Вы зарабатываете, оплачивая заявки заказчиков. В каждой заявке указана сумма заказа и ваша комиссия.
          Когда вы оплачиваете заявку, на ваш баланс поступает сумма заработанной комиссии, которая затем может быть выведена на ваш счет.
        </el-collapse-item>
        <el-collapse-item
          title="Что нужно сделать, чтобы начать выполнять заявки?"
          name="3"
        >
          Чтобы начать работу, необходимо пройти верификацию личности. Для этого выберите любую заявку в разделе "Список заявок" и нажмите "Оплатить".
          Если верификация ещё не пройдена, система автоматически предложит её пройти.
        </el-collapse-item>
        <el-collapse-item
          title="Как выбрать и оплатить заявку?"
          name="4"
        >
          В разделе "Список заявок" вы увидите все доступные заявки с указанием суммы заказа, комиссии и оставшегося времени до истечения заявки.
          Выберите заявку и нажмите "Оплатить", чтобы открыть её детали. После просмотра деталей вы сможете перейти на форму оплаты и завершить выполнение заявки.
        </el-collapse-item>
        <el-collapse-item
          title="Где я могу увидеть список всех выполненных заявок?"
          name="5"
        >
          В разделе "Выполненные заявки" отображаются все ваши выполненные задания.
          Здесь указаны дата выполнения, сумма заказа, заработанная комиссия, а также есть возможность просмотреть чек по каждой выполненной заявке.
        </el-collapse-item>
        <el-collapse-item
          title="Как узнать свой текущий баланс?"
          name="6"
        >
          Текущий баланс и историю операций можно посмотреть в разделе "Баланс".
          Здесь также отображается статистика дохода, и вы можете отслеживать свои заработки за определённый период
        </el-collapse-item>
        <el-collapse-item
          title="Как вывести средства с баланса?"
          name="7"
        >
          Для вывода средств перейдите в раздел "Баланс", где сможете выбрать способ вывода и запросить выплату.
          Обратите внимание на минимальные суммы вывода и сроки обработки платежей.
        </el-collapse-item>
        <el-collapse-item
          title="Что делать, если я забыл пароль?"
          name="8"
        >
          Если вы забыли пароль, на странице входа нажмите "Забыли пароль?".
          Следуйте инструкциям для его восстановления. Вам на почту придет письмо со ссылкой для сброса пароля.
        </el-collapse-item>
        <el-collapse-item
          title="Как изменить пароль?"
          name="9"
        >
          Чтобы изменить пароль, нажмите на свою почту в правом верхнем углу экрана и выберите "Обновить пароль" в выпадающем меню.
          Следуйте дальнейшим инструкциям на экране
        </el-collapse-item>
        <el-collapse-item
          title="Как связаться с поддержкой?"
          name="10"
        >
          Для связи с поддержкой нажмите на адрес электронной почты в правом верхнем углу экрана.
          Вы сможете отправить запрос в нашу службу поддержки, и мы постараемся помочь вам как можно скорее
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';

export default defineComponent({
  setup() {

  }
});
</script>
