<template>
  <div class="template-paginator">
    <button
      class="paginator-first"
      :disabled="isInFirstPage"
      @click="onClickFirstPage"
    />
    <button
      class="paginator-prev"
      :disabled="isInFirstPage"
      @click="onClickPreviousPage"
    />
    <button class="paginator-dots" />
    <button
      v-for="page in pages"
      :key="page.name"
      class="paginator-page"
      :class="{active: isPageActive(page.name)}"
      :disabled="page.isDisabled"
      @click="onClickPage(page.name)"
    >
      {{ page.name }}
    </button>
    <button class="paginator-dots" />
    <button
      class="paginator-next"
      :disabled="isInLastPage"
      @click="onClickNextPage"
    />
    <button
      class="paginator-next"
      :disabled="isInLastPage"
      @click="onClickNextPage"
    />
    <button
      class="paginator-last"
      :disabled="isInLastPage"
      @click="onClickLastPage"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useGlobalStore } from '@/stores/global';

export default defineComponent({
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  setup () {
    const globalStore = useGlobalStore();
    return {
      globalStore
    };
  },
  computed: {
    startPage(): number {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      return this.currentPage - 1;
    },
    pages(): {name: number, isDisabled: boolean}[] {
      const range = [];

      for (
          let i = this.startPage;
          i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
          i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    },
    isInFirstPage(): boolean {
      return this.currentPage === 1;
    },
    isInLastPage(): boolean {
      return this.currentPage === this.totalPages;
    },
    isPageActive() {
      return (page: number) => {
        return this.currentPage === page;
      };
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page: number) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    }
  }
});
</script>

<style lang="less">

</style>
