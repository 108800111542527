<template>
  <div class="template-bg">
    <div class="template-wrap">
      <div class="template-header-wrap">
        <div class="template-header-logo">
          <router-link to="/steam/#form_pay">
            <img
              src="@/assets/logo_template.svg"
              alt="topupme"
            >
          </router-link>
        </div>
        <steam-navigation/>
      </div>
      <div class="template-content-wrap">
        <slot/>
      </div>

      <div class="template-footer-wrap">
        <div class="template-footer-content-top">
          <div class="template-footer-logo">
            <router-link to="/steam/#form_pay">
              <img
                src="@/assets/logo_template.svg"
                alt="topupme"
              >
            </router-link>
          </div>
          <steam-navigation/>
        </div>


        <div class="template-footer-content-center">
          <div class="template-footer-contact">
            <h3>Контакты:</h3>
            <p>
              «TopUpMe», 241140025446<br>
              Республика Казахстан,<br>
              г. Алматы, Бостандыкский район, микрорайон Ерменсай, пер. 7, д. 35/1
            </p>
            <p>
              <a href="mailto:help@topupme.kz">help@topupme.kz</a>
            </p>
            <p>
              <a href="tel:+77711919111">+7 771 191 91 11</a>
            </p>
          </div>
        </div>

        <div class="template-footer-content-bottom">
          <a
            target="_blank"
            href="../../download/Положение_конфиденциальности_и_обработки_персональных_данных_1.pdf"
          >
            Политика конфиденциальности
          </a>
          <a
            target="_blank"
            href="../../download/Публичная_оферта_для_плательщика_1_1.pdf"
          >
            Пользовательское соглашение
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import SteamNavigation from '@/components/SteamNavigation.vue';

export default defineComponent({
  components: {
    SteamNavigation
  }
});
</script>
